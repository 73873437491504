@import '../../css/colors.scss';

.services {
    background: #6d8b8c !important;

    .service-icon {
        color: #fdeda7;
        z-index: 10;
        background: transparent;
    }

    .service-content {
        margin-top: 30px;
        padding-top: 30px;
        min-height: 250px;
    }

    i {
        font-size: 2rem;
    }

    .services-container {
        max-width: 900px;
    }

    .line-before:after,
    .line-after:after {
        background-color: #FFF;
    }

    .line-before:before,
    .line-after::before {
        opacity: 0.5;
    }
}