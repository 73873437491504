.home {
    background: url(../../assets/images/homepage-bg2.webp) no-repeat center center fixed;
    background-size: cover;

    .logo-container {
        position: absolute;
        left: 1rem;
        top: 1rem;
        height: 8rem;
        width: 8rem;
    }

    .align-self-center {
        width: 700px;
    }

    h1 {
        font-size: 2rem;
    }
}

@media (min-width: 1200px) {
    .home {
        h1 {
            font-size: 2.5rem;
        }
    }
}