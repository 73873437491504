.contact {
    background: #385253;
    color: #fff;

    a,
    a:hover {
        color: #fff;
    }

    span {
        color: #fff;
    }

    .form-control {
        border-radius: 0;
        border-color: #fff;
    }

    .line-before:after,
    .line-after:after {
        background-color: #fff;
    }

    .line-before:before,
    .line-after::before {
        opacity: 0.5;
    }

    #map {
        height: calc(100% - 190px) !important;
    }

    .mapouter {
        text-align: right;
        height: 100%;
        width: 100%;
    }
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        width: 100%;
    }
    #gmap_canvas {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .contact {
        #gmap_canvas {
            height: 100%;
        }
    }
}

.react-datepicker-wrapper {
    width: 100%;
}
