@import '../../css/colors.scss';

.realisations {
    background: #FFFFFF !important;
}

.realisation {
    overflow: hidden;
    position: relative;
    text-align: center;
    -webkit-box-shadow: 1px 1px 2px #e6e6e6;
    -moz-box-shadow: 1px 1px 2px #e6e6e6;
    box-shadow: 1px 1px 2px #e6e6e6;
    cursor: default;

    .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        background-color: #6d8b8c;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        -ms-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;        
    }

    h2 {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        background: transparent;
        margin: 30px 50px 30px 50px;
        padding: 0 0 9px 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        color: #FFF;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        -ms-transition: all 0.5s linear;
        transition: all 0.5s linear;
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
    }

    p {
        color: #FFF;
        margin: 0 40px;
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        -ms-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }

    img {
        -webkit-transform: scaleY(1);
        -moz-transform: scaleY(1);
        -o-transform: scaleY(1);
        -ms-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transition: all 0.7s ease-in-out;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    &:hover {
        .mask {
            -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
        }
        h2 {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
        }
        p {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            opacity: 1;
        }
        img {
            -webkit-transform: scale(10);
            -moz-transform: scale(10);
            -o-transform: scale(10);
            -ms-transform: scale(10);
            transform: scale(10);
            -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            opacity: 0;
        }
    }
}