@import '../../css/colors.scss';

.nav.fixed-top {
    top: 15px;
    right: 50px;
    position: absolute !important;
}

a.nav-link, a.nav-link:hover, a.nav-link:active, a.nav-link:focus, a.nav-link:visited {
    color: $white;
    padding: 0;
    font-weight: 600
}

.nav-item {
    margin-right: 1.5rem;
}

.nav-item:hover, .nav-item.active {
    border-bottom: 1px solid $white;
}